<template>
  <b-row
    class="mt-2"
  >
    <b-card-text
      v-if="frm.choose === 1"
      class="choose-img"
      @click="showModal(1)"
    >
      <feather-icon
        icon="ImageIcon"
        size="20"
      />
      Thư viện ảnh
    </b-card-text>
    <b-card-text
      v-else-if="frm.choose === 2"
      class="choose-img"
      @click="showModal(3)"
    >
      <feather-icon
        icon="UploadCloudIcon"
        size="20"
      />
      {{ $t('document') }}
    </b-card-text>
    <b-card-text
      v-else-if="frm.choose === 3"
      class="choose-img"
      @click="showModal(2)"
    >
      <feather-icon
        icon="Volume2Icon"
        size="20"
      />
      Thư viện audio
    </b-card-text>
    <template v-else-if="frm.choose === 4">
      <b-col
        cols="6"
        class="upload-image"
      >
        <label @click="showModal(1)"> {{ $t('image') }} </label>
        <b-img
          v-if="frm.image"
          style="margin-left: 0px !important; max-width: 100%"
          center
          class="mb-1 mt-1"
          height="200"
          :src="endPoint + '/storage' + frm.image.url"
        />
        <feather-icon
          v-if="frm.image"
          icon="XCircleIcon"
          color="#ff7f00"
          size="20"
          class="cursor-pointer remove-image"
          @click="removeImg"
        />
      </b-col>
      <b-col cols="6">
        <label style="border: none">{{ $t('audio') }} </label>
        <b-row>
          <b-col md="4">
            <b-form-select
              v-model="frm.audio_type"
              :options="optionTypeAudio"
            />
          </b-col>
          <b-col md="8">
            <b-form-group class="form_custom">

              <validation-provider
                v-if="frm.audio_type === 'link'"
                #default="{ errors }"
                name="link"
                rules="required|url: {require_protocol: true }"
              >
                <b-form-input
                  v-model="frm.audio_link"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nhập đường dẫn audio"
                  @change="emitUploadMedia"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider
                v-if="frm.audio_type === 'upload'"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="danger"
                  class="btn-sm"
                  @click="showModal(2)"
                >
                  <feather-icon icon="UploadCloudIcon" />
                  Tải lên
                </b-button>
              </validation-provider>

            </b-form-group>
          </b-col>
        </b-row>
        <audio
          v-if="frm.audio "
          class="mt-2"
          controls
        >
          <source
            :src="endPoint + '/storage' + frm.audio.url"
            type="audio/ogg"
          >
        </audio>

      </b-col>
    </template>
    <template v-else-if="frm.choose === 5">
      <b-col cols="6">
        <div class="multiple-image">
          <vue-upload-multiple-image
            :data-images="frm.multipleImage"
            @upload-success="uploadImage"
            @before-remove="beforeRemove"
            @edit-image="editImage"
          />
        </div>

      </b-col>
      <b-col
        cols="6"
        class="upload-image"
      >
        <label
          class="audio-label"
          @click="showModal(2)"
        >{{ $t('audio') }} </label>
        <audio
          v-if="frm.audio "
          class="mt-2"
          controls
        >
          <source
            :src="endPoint + '/storage' + frm.audio.url"
            type="audio/ogg"
          >
        </audio>
        <feather-icon
          v-if="frm.audio"
          icon="Trash2Icon"
          size="18"
          class="cursor-pointer remove-audio"
          @click="removeAudio"
        />
      </b-col>
    </template>
    <template v-else>
      <b-col cols="6">
        <label @click="showModal(1)"> {{ $t('image') }} </label>
        <b-img
          v-if="frm.image"
          style="margin-left: 0px !important; max-width: 100%"
          center
          class="mb-1 mt-1"
          height="200"
          :src="endPoint + '/storage' + frm.image.url"
        />
        <feather-icon
          v-if="frm.image"
          icon="XCircleIcon"
          color="#ff7f00"
          size="20"
          class="cursor-pointer remove-image"
          @click="removeImg"
        />
      </b-col>
      <b-col
        cols="6"
        class="upload-image"
      >
        <label
          class="audio-label"
          @click="showModal(2)"
        >{{ $t('audio') }} </label>
        <audio
          v-if="frm.audio "
          class="mt-2"
          style="width: 80%"
          controls
        >
          <source
            :src="endPoint + '/storage' + frm.audio.url"
            type="audio/ogg"
          >
        </audio>
        <feather-icon
          v-if="frm.audio"
          icon="Trash2Icon"
          size="18"
          class="cursor-pointer remove-audio"
          @click="removeAudio"
        />
      </b-col>
    </template>
    <b-modal
      id="modal-edit"
      v-model="modalShow"
      ok-variant="danger"
      title="Thư viện file phương tiện"
      centered
      size="xl"
      hide-footer
      ok-only
      :ok-title="$t('save_info')"
      @hide="clearUpload"
    >
      <b-tabs align="center">
        <b-tab
          :title="$t('libary')"
          active
          @click="tab = true"
        >
          <b-col cols="12">
            <b-row>
              <b-col cols="3" />
              <b-col cols="3" />
              <b-col md="3" />
              <b-col md="3">
                <label for="">
                  {{ $t("Search") }}
                </label>
                <b-form-input
                  v-model="filterFile.key"
                  :placeholder="$t('search_key')"
                  @change="loadLibraryFile"
                />
              </b-col>
            </b-row>
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    width="10"
                  >
                    STT
                  </th>
                  <th
                    scope="col"
                    width="10"
                    center
                  >
                    {{ $t("name_q") }}
                  </th>
                  <th
                    width="100"
                    scope="col"
                  >
                    {{ $t('crt') }}
                  </th>
                </tr>
              </thead>
              <tr
                v-for="(item, index) in data"
                :id="item.id"
                :key="item.id"
              >
                <td>
                  <b-form-radio
                    v-if="typeUpload === 1"
                    v-model="frm.image"
                    :value="item"
                    class="custom-control-danger"
                    name="check-button"
                    @change="uploadMedia(false, 1)"
                  />
                  <b-form-radio
                    v-else-if="typeUpload === 3"
                    v-model="frm.document"
                    :value="item"
                    class="custom-control-danger"
                    name="check-button"
                    @change="uploadMedia(false, 3)"
                  />
                  <b-form-radio
                    v-else
                    v-model="frm.audio"
                    :value="item"
                    class="custom-control-danger"
                    name="check-button"
                    @change="uploadMedia(false, 2)"
                  />
                </td>
                <td scope="row">
                  <a
                    v-if="item.type===1"
                    target="_blank"
                    class="d-flex justify-content-md-start align-items-center"
                    :href="endPoint + '/storage' + item.url"
                  >
                    <feather-icon
                      icon="ImageIcon"
                      class="cursor-pointer"
                      size="20"
                    />

                    {{ item.name }}
                  </a>
                  <a
                    v-else-if="item.type === 3"
                    target="_blank"
                    :href="isUrl(item.name) ? item.url : endPoint + '/storage' + item.url"
                  >
                    <feather-icon
                      icon="BookIcon"
                      class="cursor-pointer"
                      size="20"
                    />
                    {{ item.name }}
                  </a>
                  <a
                    v-else
                    target="_blank"
                    :href="isUrl(item.name) ? item.url : endPoint + '/storage' + item.url"
                  >
                    <feather-icon
                      icon="VolumeIcon"
                      class="cursor-pointer"
                      size="20"
                    />
                    {{ item.name }}
                  </a>
                </td>
                <td>
                  {{ convertDate(item.created_at) }}
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPageFile"
              :total-rows="totalFile"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChangeFile"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-tab>
        <b-tab
          :title="$t('upload_cp')"
          @click="tab = false"
        >
          <b-row>
            <b-col cols="3" />
            <b-col cols="6">
              <validation-observer ref="addMedia">
                <validation-provider
                  v-if="typeUpload === 1"
                  #default="{ errors }"
                  :name="$t('img')"
                  rules="image|size:5120"
                >
                  <div class="group_file_upload">
                    <b-form-file
                      v-model="ima"
                      placeholder="Tải lên ảnh thumbnail (tỉ lệ 16:9, dung lượng < 5MB)"
                      drop-placeholder="Drop file here..."
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      @change="onFileChange"
                    />
                    <b-card-text
                      v-if="imgUrl"
                      class="block-img"
                    >
                      <b-img
                        class="mb-1 mb-sm-0"
                        height="120"
                        :src="imgUrl"
                        alt="Right image"
                      />
                    </b-card-text>
                  </div>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-form-file
                  v-else-if="typeUpload === 3"
                  v-model="dct"
                  class="mb-2"
                  placeholder="Click tải lên tài liệu"
                  drop-placeholder="Kéo thả tài liệu vào đây"
                  accept="application/pdf"
                  @change="onFileChangeDocument"
                />
                <b-form-file
                  v-else
                  v-model="adi"
                  class="file_media"
                  accept="audio/*"
                  :placeholder="$t('placehorder_audio')"
                  size="md"
                  @change="onFileChangeAudio"
                />
              </validation-observer>
              <b-button
                variant="danger"
                type="submit"
                :disabled="disable"
                class="btn-sm"
                style="margin-left: 44%; margin-bottom: 10px"
                @click="uploadMedia(true, typeUpload)"
              >
                {{ $t('upload') }}
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-modal>
  </b-row>

</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  VBModal,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed,
  BTab,
  BTabs,
  BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Table from '@/views/table/bs-table/Table'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import BCardCode from '@core/components/b-card-code/BCardCode'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import draggable from 'vuedraggable'
import VueUploadMultipleImage from 'vue-upload-multiple-image'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Table,
    VueUploadMultipleImage,
    BCardText,
    AppCollapse,
    AppCollapseItem,
    BCardCode,
    BImg,
    vSelect,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'vue-upload-multiple-image': VueUploadMultipleImage,
  },
  props: {
    image: {
      required: false,
      default: null,
    },
    audio: {
      required: false,
      default: null,
    },
    choose: {
      required: false,
      default: null,
    },
    audio_type: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      disable: true,
      imgUrl: null,
      tab: true,
      optionTypeAudio: [
        {
          text: 'Gán link',
          value: 'link',
        },
        {
          text: 'Tải lên',
          value: 'upload',
        },
      ],
      isLoading: false,
      currentPageFile: 1,
      totalFile: 0,
      pageLength: 10,
      modalShow: false,
      ima: null,
      adi: null,
      dct: null,
      typeAudio: null,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filterFile: {
        key: '',
        type: 'all',
      },
      frm: {
        image: null,
        audio: null,
        document: '',
        choose: null,
        audio_type: null,
        audio_link: null,
        multipleImage: this.image,
      },
      data: [],
      typeUpload: false,
      // eslint-disable-next-line no-undef
    }
  },
  watch: {
    'frm.audio_type': {
      handler(newVal) {
        if (newVal === 'link') {
          this.frm.audio = null
        } else if (newVal === 'upload') {
          this.frm.audio_link = null
        }
      },
    },
  },
  mounted() {
    if (this.choose === 5) {
      document.getElementsByClassName('image-container')[0].style.width = '100%'
    }
  },
  created() {
    this.frm.image = this.image
    this.frm.audio = this.audio
    this.frm.choose = this.choose
    this.frm.audio_type = this.audio_type
    if (this.audio_type === 'link') {
      this.frm.audio_link = this.audio.url
    }
  },
  methods: {
    onFileChange(e) {
      this.disable = false
      const file = e.target.files[0]
      this.imgUrl = URL.createObjectURL(file)
    },
    beforeRemove(index, done, fileList) {
      const r = confirm('Bạn có chắc chắn muốn xóa ảnh ?')
      if (r == true) {
        done()
        this.frm.multipleImage = fileList
        this.emitUploadMedia()
      }
    },
    editImage(formData, index, fileList) {
      this.uploadImage(formData, index, fileList, false)
    },
    uploadImage(formData, index, fileList, store = true) {
      const formDataUpload = new FormData()
      // Upload image api
      const url = '/files'
      formDataUpload.append('image', formData.get('file'))
      this.$http({
        method: 'post',
        url,
        data: formDataUpload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        console.log(store)
        if (store) {
          this.frm.multipleImage.push(res.data)
        } else {
          // eslint-disable-next-line no-param-reassign
          fileList[index] = res.data
          this.frm.multipleImage = fileList
        }
        this.emitUploadMedia()
      }).catch(e => {
        console.log(e)
      })
    },
    onFileChangeAudio(e) {
      this.disable = false
    },
    onFileChangeDocument(e) {
      this.disable = false
    },
    emitUploadMedia() {
      this.$emit('uploadMedia', this.frm)
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    showModal(flag) {
      this.modalShow = true
      this.typeUpload = flag
      this.filterFile.type = flag
      this.loadLibraryFile()
    },
    onPageChangeFile(currentPage) {
      this.currentPageFile = currentPage
      this.loadLibraryFile()
    },
    loadLibraryFile() {
      this.isLoading = true
      this.$http.get('/files', {
        params: {
          perPage: this.pageLength,
          page: this.currentPageFile,
          keyword: this.filterFile.key,
          type: this.filterFile.type,
        },
      })
        .then(res => {
          this.data = res.data.data
          this.totalFile = res.data.total
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    clearUpload(typeUpload) {
      this.disable = true
      this.imgUrl = null
      if (typeUpload === 1) {
        this.ima = null
      } else if (typeUpload === 2) {
        this.adi = null
      } else {
        this.dct = null
      }
    },
    isUrl(string) {
      const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/
      return matcher.test(string)
    },
    removeImg() {
      this.frm.image = ''
      this.emitUploadMedia()
    },
    removeAudio() {
      this.frm.audio = ''
      this.emitUploadMedia()
    },
    uploadMedia(tmp, typeUpload) {
      if (!this.tab) {
        this.$refs.addMedia.validate().then(success => {
          if (success) {
            const url = '/files'
            const formData = new FormData()

            if (this.ima) {
              formData.append('image', this.ima)
            }
            if (this.adi) {
              formData.append('audio', this.adi)
            }
            if (this.dct) {
              formData.append('document', this.dct)
            }
            this.$http({
              method: 'post',
              url,
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })

            // eslint-disable-next-line no-unused-vars
              .then(res => {
                this.$bvToast.toast(this.$t('upload_success'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'success',
                  solid: false,
                })
                const files = res.data
                // eslint-disable-next-line array-callback-return
                if (files.type === 1) {
                  this.frm.image = files
                } else if (files.type === 2) {
                  this.frm.audio = files
                } else {
                  this.frm.document = files
                }
                this.clearUpload(typeUpload)
                this.tab = true
                this.modalShow = false
                this.$emit('uploadMedia', this.frm)
              })
            // eslint-disable-next-line no-unused-vars
              .catch(error => {
                this.$bvToast.toast(this.$t('upload_err'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'danger',
                  solid: false,
                })
              }).finally(() => {
                this.isLoading = false
              })
          }
        })
      } else {
        this.modalShow = false
        this.$emit('uploadMedia', this.frm)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.choose-img{
  cursor: pointer;
}
.upload-image{
  position: relative;
}
.remove-image{
  position: absolute;
  top: 40px;
  right: 15px;
}
.remove-audio{
  position: absolute;
  top: 76px;
  right: 44px;
}
.audio-label{
  display: block;
  width: 40%;
}
</style>

<template>
  <b-overlay
    rounded="sm"
  >
    <b-card-code>
      <validation-observer ref="addQuestion">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('name_q')"
                rules="required"
              >
                <label :class="errors.length > 0 ? 'text-danger': null">
                  Nhập câu hỏi  <span class="text-danger">(*)</span></label>
                <b-form-textarea
                  v-model="frm.questionParents.name"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="'Gắn tag'"
                rules="required"
              >
                <label
                  :class="errors.length > 0 ? 'text-danger': null"
                >Gắn tag <span class="text-danger">(*)</span></label>
                <router-link
                  target="_blank"
                  class="float-right"
                  :to="{ name: 'tag-question' }"
                >
                  Tạo mới
                </router-link>
                <v-select
                  id="tag"
                  v-model="frm.questionParents.tag"
                  multiple
                  :reduce="label => label.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="tagOptions"
                  :create-option="org => ({ id: org , name: org})"
                  :state="errors.length > 0 ? false:null"
                  :clearable="false"
                  @search="onSearch"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <app-collapse class="extend-review">
            <app-collapse-item title="Mở rộng">
              <b-form-group label="Tag đánh giá">
                <v-select
                  v-model="frm.questionParents.tag_review_id"
                  :reduce="label => label.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="tagOptionByReview"
                  taggable
                  :clearable="false"
                />
              </b-form-group>
              <b-form-group label="Giải thích đáp án">
                <quill-editor
                  v-model="frm.questionParents.explain"
                  max-rows="6"
                />
              </b-form-group>
            </app-collapse-item>
          </app-collapse>
        </b-row>
        <b-row class="mt-3">
          <b-col md="6">
            <div class="form-label-group d-flex">
              <b-form-checkbox
                id="status"
                v-model="frm.questionParents.status"
                class="custom-control-danger"
                name="check-button"
                switch
              />
              <div>{{ $t('status') }}</div>
            </div>
          </b-col>
        </b-row>
        <b-col cols="12">
          <div class="demo-inline-spacing float-right">
            <b-button
              variant="danger"
              class="btn-sm"
              @click="submit"
            >
              {{ frm.questionParents.id ? 'Cập nhật' : $t('save_info') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="btn-sm"
            >
              Trở lại
            </b-button>
          </div>
        </b-col>
      </validation-observer>
    </b-card-code>
  </b-overlay>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import config from '@/config'
import Ripple from 'vue-ripple-directive'
import uploadMedia from '@/views/library/UploadMedia'
import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed,
  BTab,
  BTabs,
  BCardText, VBTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import Table from '@/views/table/bs-table/Table'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BSkeleton,
    VueAutosuggest,
    Table,
    uploadMedia,
    BCardText,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BImg,
    vSelect,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
    BTab,
    BTabs,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    question: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      tagOptions: [],
      tagOptionByReview: [],
      logOptionTag: [],
      frm: {
        questionParents: {
          id: '',
          name: null,
          tag: [],
          tag_review_id: null,
          explain: null,
          type: 2,
          status: true,
          // image: this.$route.params.data ? this.$route.params.data.image : this.question ? this.question.image : null,
          // audio: this.$route.params.data ? this.$route.params.data.audio : this.question ? this.question.audio : null,
        },
      },

    }
  },
  mounted() {
    this.loadTagReviews()
    if (this.$route.params.data) this.loadData(this.$route.params.data)
    if (this.question) this.loadData(this.question)
  },
  methods: {
    loadTagReviews() {
      this.isLoading = true
      this.$http.get('/tags', {
        params: {
          type: 1,
          perPage: 100,
        },
      })
        .then(res => {
          this.tagOptionByReview = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    submit() {
      this.$refs.addQuestion.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          const tags = []
          const newTags = []
          if (this.frm.questionParents.tag.length) {
            this.frm.questionParents.tag.map(v => {
              v === parseInt(v) ? tags.push(v) : newTags.push({ name: v })
            })
            formData.append('tags', JSON.stringify(tags))
            formData.append('newTags', JSON.stringify(newTags))
          }
          // handle param
          formData.append('questionParents', JSON.stringify(this.frm.questionParents))

          // if (this.frm.questionParents.audio) {
          //   formData.append('audio_id', this.frm.questionParents.audio.id)
          // }
          // if (this.frm.questionParents.image) {
          //   formData.append('image_id', this.frm.questionParents.image.id)
          // }
          const url = this.frm.questionParents.id ? `/questions/${this.frm.questionParents.id}` : '/questions'
          if (this.frm.questionParents.id) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              if (this.question) {
                // this.loadData(res.data)
                this.$emit('question', this.frm.questionParents)
              } else {
                this.$router.back()
              }
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data.errors
              } else {
                errorMsg.name = [error.response.data.error]
              }
              this.$refs.addQuestion.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    loadData(question) {
      const tags = []
      this.frm.questionParents.id = question.id
      this.frm.questionParents.name = question.name
      this.frm.questionParents.type = question.type
      this.frm.questionParents.tag = question.tag
      this.frm.questionParents.answers = question.answers
      this.frm.questionParents.status = question.status ? 1 : 0
      this.frm.questionParents.explain = question.explain
      this.frm.questionParents.tag_review_id = question.tag_review_id
      if (question.tags) {
        question.tags.map(v => {
          tags.push(v.id)
          this.logOptionTag.push(v)
          this.tagOptions.push(v)
        })
        this.frm.questionParents.tag = tags
      }
      if (question.answers) {
        question.answers.map((v, index) => {
          if (v.is_correct === 1) {
            this.frm.questionParents.is_correct = index
          }
        })
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.loadTagByName(search, loading, this.$http, this)
      }
    },
    loadTagByName: _.debounce((search, loading, call, vm) => {
      call.get('/tags', {
        params: {
          perPage: 100,
          key: search,
          type: 0,
        },
      })
        .then(res => {
          vm.tagOptions = res.data.data
          if (vm.logOptionTag.length) {
            vm.logOptionTag.map(v => {
              vm.tagOptions.push(v)
            })
            vm.tagOptions = _.uniqBy(vm.tagOptions, 'id')
          }
          loading(false)
        }).catch()
        .finally(() => {
        })
    }, 250),
  },

}
</script>
<style lang="scss" scoped>
.extend-review{
  width: 100%;
  padding: 12px;
  .card{
    border: 1px solid #d8d6de;
    box-shadow: none;
    border-radius: 6px;
  }
}
.card{
  box-shadow: none;
}
</style>
